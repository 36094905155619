import {render, remove, RenderPosition} from '../utils/render.js';
import ButtonComponent from '../component/base/button.js';
import SpanComponent from '../component/base/span.js';

export default class ButtonController {
  constructor({
    container,
    name = ``,
    title = ``,
    prompt = ``,
    className = `modal__button`,
    isNew = false,
    style = ``,
    styleMode = ``,
    size = `medium`,
    dataCount = ``,
    isDisabled = false,
    isActive = false,
    renderPosition = RenderPosition.BEFOREEND,
    withClick = null,
    onClickHandler = null,
    onDoubleClickHandler = null,
    onClickWithLoader = null,
  }) {
    this._container = container;
    this._name = name;
    this._title = title;
    this._prompt = prompt;
    this._className = className;
    this._isNew = isNew;
    this._style = style;
    this._styleMode = styleMode;
    this._size = size;
    this._dataCount = dataCount;
    this._isDisabled = isDisabled;
    this._isActive = isActive;
    this._renderPosition = renderPosition;
    this._withClick = withClick;
    this._onClickHandler = onClickHandler;
    this._onDoubleClickHandler = onDoubleClickHandler;
    this._onClickWithLoader = onClickWithLoader;

    this._isLoad = false;

    this._button = null;
  }

  render() {
    this._button = new ButtonComponent({
      className: this._className,
      name: this._name,
      title: this._title,
      prompt: this._prompt,
      isDisabled: this._isDisabled,
    });
    render(this._container, this._button.getElement(), this._renderPosition);

    this._button.getElement().dataset.active = this._isActive;

    if (this._style !== ``) {
      this._button.getElement().style = this._style;
    }

    if (this._styleMode !== ``) {
      this._button.getElement().dataset.style = this._styleMode;
    }

    if (this._dataCount !== ``) {
      this._button.getElement().dataset.count = this._dataCount;
    }

    this._button.getElement().dataset.size = this._size;

    if (this._isNew) {
      const newComponent = new SpanComponent({
        className: `new__text`,
        text: `NEW`
      });

      render(this._button.getElement(), newComponent.getElement(), RenderPosition.BEFOREEND);
    }

    this._button.setClickHandler(this._getClickHandler());
    this._button.setDoubleClickHandler(this._getDoubleClickHandler());
    this._button.setClickWithLoaderHandler(this._getClickWithLoaderHandler());
  }

  getElement() {
    return this._button.getElement();
  }

  setClickHandler(handler) {
    this._button.setClickHandler(handler);
  }

  remove() {
    remove(this._button);
    this._withClick = null;
    this._onClickHandler = null;
    this._onDoubleClickHandler = null;
    this._onClickWithLoader = null;
  }

  setCount(count = ``) {
    this._button.getElement().dataset.count = count || ``;
  }

  setDataAttribute(attribute, data = ``) {
    if (!attribute) {
      return;
    }
    this._button.getElement().dataset[attribute] = data;
  }

  get isDisabled() {
    return this._isDisabled;
  }

  set isDisabled(value) {
    this._button.isDisabled = this._isDisabled = value;
  }

  set style(data) {
    this._button.getElement().style = data;
  }

  set title(data) {
    this._button.title = data;
  }

  set container(data) {
    this._container = data;
  }

  set isLoad(data = false) {
    this._isLoad = data;

    if (this._isLoad) {
      this.getElement().classList.add(`loaderButton`);
    } else {
      this.getElement().classList.remove(`loaderButton`);
    }
  }

  _getClickHandler() {
    if (!this._onClickHandler) {
      return null;
    }

    if (!this._withClick) {
      return this._onClickHandler;
    }

    return this._withClick(this._onClickHandler);
  }

  _getDoubleClickHandler() {
    if (!this._onDoubleClickHandler) {
      return null;
    }

    if (!this._withClick) {
      return this._onDoubleClickHandler;
    }

    return this._withClick(this._onDoubleClickHandler);
  }

  _getClickWithLoaderHandler() {
    if (!this._onClickWithLoader) {
      return null;
    }

    if (!this._withClick) {
      return this._onClickWithLoader;
    }

    return this._withClick(this._onClickWithLoader);
  }

  click() {
    this._button.getElement().click();
  }
}
